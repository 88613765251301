import { createContext, useContext } from "react";
import { DployBrandThemeAdditions } from "./providers/TenantProvider";
import { Tenant } from "./types";

export interface Brand {
	images: Record<string, string[]>;
}

export interface TenantsConfig {
	tenant?: Tenant;
	locale: string;
	design?: string;
	brand?: Brand;
	brandAdditions?: DployBrandThemeAdditions;
}

const context = createContext<TenantsConfig>({
	locale: ""
});
context.displayName = "Tenant";

export default context;

export const TenantConsumer = context.Consumer;

export function useTenant(): TenantsConfig {
	return useContext(context);
}

import { apiResourceUrl } from "@ploy-lib/core";
import paramsToString, { BaseResource } from "./BaseResource";
import { ProductResource } from "./ProductResource";
import {
	LimitApplicationDataResource,
	LimitApplicationDataCustomerResource
} from "./LimitApplicationResource";
import { LimitRedistributionResource } from "./LimitRedistributionResource";
import { BeneficialOwnerResource } from "./BeneficialOwnerResource";
import { ApplicationResource } from "./ApplicationResource";
import startCase from "lodash/startCase";

import {
	ID,
	FormTemplate,
	TemplatePage,
	TemplatePageDeprecated,
	TemplatePanel,
	TemplateSection,
	TemplateField,
	PaletteColorKey,
	GridSize,
	TemplateTableColumn,
	TextAlignment,
	FieldWidth,
	ErrorDisplay,
	GridJustification,
	OptionSource,
	TableColumnOptionSource,
	FieldService,
	FieldSave,
	FieldClick,
	isTemplatePage,
	TemplateDataSource,
	CustomGuiFieldHandling
} from "@ploy-lib/types";
import {
	AbstractInstanceType,
	Endpoint,
	Entity,
	Resource,
	schema
} from "@rest-hooks/rest";
import { EmbeddedProductFieldResource } from "./EmbeddedProductFieldResource";

export interface FormTemplateParams {
	productExternalCode?: string;
	applicationNumber?: string;
	formContext?: string;
	context?: string;
}

export interface FormTemplateParams {
	productExternalCode?: string;
	applicationNumber?: string;
	formContext?: string;
	context?: string;
}

class TemplateTableColumnEntity extends Entity implements TemplateTableColumn {
	readonly formTemplateTableColumnId: string = "";
	readonly name: string = "";
	readonly tableType: string = "";
	readonly showSum?: boolean = undefined;
	readonly editable?: boolean = undefined;
	readonly isMultipleSelect?: boolean = undefined;
	readonly optionValues?: { key: string; value: string }[] = undefined;
	readonly allOptionValues?: { key: string; value: string }[] = undefined;
	readonly optionSource?: TableColumnOptionSource = undefined;
	readonly isGroupingColumn?: boolean = undefined;
	readonly hrefLink?: string = "";
	readonly groupLabel?: string = "";
	readonly footerGroupLabel?: string = "";

	pk() {
		return this.formTemplateTableColumnId;
	}
}

class TemplateFieldEntity extends Entity implements TemplateField {
	readonly formTemplateFieldId: string = "";
	readonly namespace?: string = undefined;
	readonly name: string = "";
	readonly storageName: string = "";
	readonly initialFieldValue?: string = undefined;
	readonly modifier?: string = undefined;
	readonly label?: string = undefined;
	readonly tooltip?: string = undefined;
	readonly placeholder?: string = undefined;
	readonly prefix?: string = undefined;
	readonly valueSuffix?: string = undefined;
	readonly textAreaRows?: number = undefined;
	readonly modalText?: string = undefined;
	readonly disabled?: boolean = undefined;
	readonly literal?: boolean = undefined;
	readonly textAlign?: TextAlignment = undefined;
	readonly alignWithPrevious?: boolean = undefined;
	readonly alwaysVisible?: boolean = undefined;
	readonly alwaysEnabled?: boolean = undefined;
	readonly isRequired?: boolean = undefined;
	readonly hiddenLabel?: boolean = undefined;
	readonly width?: FieldWidth = undefined;
	readonly role?: string = undefined;
	readonly renderAs?: string = undefined;
	readonly variant?: string = undefined;
	readonly renderAsLiteral?: string = undefined;
	readonly literalVariant?: string = undefined;
	readonly horizontal?: boolean = undefined;
	readonly color?: string = undefined;
	readonly errorDisplay?: ErrorDisplay = undefined;
	readonly errorDisplayLiteral?: ErrorDisplay = undefined;
	readonly hideErrorWhenLiteral?: boolean = undefined;
	readonly hideForMobile?: boolean = undefined;
	readonly fullWidth?: boolean = undefined;
	readonly mailto?: boolean = undefined;
	readonly linkIsUrl?: boolean = undefined;
	readonly alignCenterInParent?: boolean = undefined;
	readonly boldText?: boolean = undefined;
	readonly italicText?: boolean = undefined;
	readonly helperText?: string = undefined;
	readonly suffix?: string = undefined;
	readonly margin?: string = undefined;
	readonly multiple?: boolean = undefined;
	readonly manual?: boolean = undefined;
	readonly justifyContent?: GridJustification = undefined;
	readonly icon?: string = undefined;
	readonly uppercaseLabel?: boolean = undefined;
	readonly allowEmpty?: boolean = undefined;
	readonly hiddenIfNoChoice?: boolean = undefined;
	readonly searchable?: boolean = undefined;
	readonly emptyEqualsZero?: boolean = undefined;
	readonly href?: string = undefined;
	readonly target?: string = undefined;
	readonly to?: string = undefined;
	readonly optionSource?: OptionSource = undefined;
	readonly search?: FieldService = undefined;
	readonly save?: FieldSave = undefined;
	readonly click?: FieldClick = undefined;
	readonly open?: FieldService = undefined;
	readonly globalEvent?: string = undefined;
	readonly formatString?: string = undefined;
	readonly canResetWriteLocked?: boolean = undefined;
	readonly optionPairCategoryName?: string = undefined;
	readonly useFieldSeperator?: boolean = undefined;

	readonly requireCustomGuiField?: CustomGuiFieldHandling =
		CustomGuiFieldHandling.Require;

	pk() {
		return this.formTemplateFieldId;
	}
}

class TemplateSectionEntity extends Entity implements TemplateSection {
	readonly formTemplateSectionId: string = "";
	readonly sectionTemplateId?: number = undefined;
	readonly title?: string = undefined;
	readonly internalDescription?: string = undefined;
	readonly style?: string = undefined;
	readonly layout?: string = undefined;
	readonly fields: TemplateFieldEntity[] = [];
	readonly literal?: boolean = undefined;
	readonly tabGroup?: string = undefined;
	readonly tableColumns: TemplateTableColumnEntity[] = [];

	readonly expansionGroup?: string | undefined = undefined;

	readonly group?: string = undefined;
	readonly expansionTitle?: string = undefined;
	readonly displaySectionTitle?: boolean = undefined;
	readonly showWhenCollapsed?: boolean = undefined;
	readonly enableExpansion?: boolean = undefined;
	readonly expandInitially?: boolean = undefined;
	readonly expansionTitleUppercase?: boolean = true;
	readonly outerWidthSM?: GridSize = undefined;
	readonly groupBackgroundColor?: string = undefined;
	readonly innerWidthSM?: GridSize = undefined;
	readonly useSeparator?: boolean = undefined;
	readonly elevation?: number = undefined;
	readonly square?: boolean = undefined;
	readonly outline?: PaletteColorKey = undefined;
	readonly outlineWidth?: number = undefined;
	readonly hideForMobile?: boolean = undefined;
	readonly nextLabel?: string = undefined;
	readonly prevLabel?: string = undefined;
	readonly mainText?: string = undefined;
	readonly helperText?: string = undefined;
	readonly showStatus?: boolean = undefined;
	readonly showUpdateSupplier?: boolean = undefined;
	readonly hideSectionIfNoVisibleFields?: boolean = undefined;
	readonly standardMargin?: boolean = undefined;
	readonly centerText?: boolean = undefined;
	readonly showDialogButtons?: boolean = undefined;
	readonly hideSignerList?: boolean = undefined;
	readonly hideSignerEditing?: boolean = undefined;
	readonly showAlternativeMessage?: boolean = undefined;
	readonly fullWidthOpenButton?: boolean = undefined;
	readonly showUpdateCoSigner?: boolean = undefined;
	readonly useResourceBinding?: boolean = undefined;
	readonly tableType?: string = undefined;
	readonly tableIsEditable?: boolean = undefined;
	readonly mergeColumnValues?: boolean = undefined;
	readonly submitOnRowChange?: boolean = undefined;
	readonly borderlessTable?: boolean = undefined;
	readonly tableFilter?: string[] = [];
	readonly multiselect?: boolean = undefined;
	readonly canDeleteRow?: boolean = undefined;
	readonly canAddNewRows?: boolean = undefined;
	readonly editButtonAtBottom?: boolean = undefined;
	readonly hideEditAndSaveIcons?: boolean = undefined;
	readonly moveFirstItemToTitle?: boolean = undefined;

	pk() {
		return this.formTemplateSectionId;
	}

	static schema = {
		fields: [TemplateFieldEntity],
		tableColumns: [TemplateTableColumnEntity]
	};
}

class TemplatePanelEntity extends Entity implements TemplatePanel {
	readonly formPanelId: string = "";
	readonly title?: string = undefined;
	readonly sections: TemplateSectionEntity[] = [];
	readonly initialTab?: string = undefined;
	readonly elevation?: number = undefined;
	readonly square?: boolean = undefined;
	readonly hideForMobile?: boolean = undefined;
	readonly desktopViewForMobile?: boolean = undefined;
	readonly minHeight?: number = undefined;
	readonly literal?: boolean = undefined;

	pk() {
		return this.formPanelId;
	}

	static schema = {
		sections: [TemplateSectionEntity]
	};
}

class TemplatePageEntity extends Entity implements TemplatePage {
	readonly formPageId: string = "";
	readonly pageTitle?: string = undefined;
	readonly pageUri?: string = undefined;
	readonly layout?: string = undefined;
	readonly prevLabel?: string = undefined;
	readonly nextLabel?: string = undefined;
	readonly submitLabel?: string = undefined;
	readonly panels: Record<string, TemplatePanelEntity> = {};
	readonly description?: string = undefined;
	readonly displayInStepper?: boolean = undefined;
	readonly headerIconName?: string = undefined;
	readonly hideFunctionButtons?: boolean = undefined;
	readonly reduceFormHeightForLaptops?: boolean = undefined;
	readonly maxWidth?: number = undefined;
	readonly gtmPageEvent?: string = undefined;

	pk() {
		return this.formPageId;
	}

	static schema = {
		panels: new schema.Values(TemplatePanelEntity)
	};
}

class TemplatePageDeprecatedEntity
	extends Entity
	implements TemplatePageDeprecated
{
	readonly formPageId: string = "";
	readonly pageTitle?: string = undefined;
	readonly pageUri?: string = undefined;
	readonly layout?: string = undefined;
	readonly prevLabel?: string = undefined;
	readonly nextLabel?: string = undefined;
	readonly submitLabel?: string = undefined;
	readonly sections: TemplateSectionEntity[] = [];
	readonly summary: TemplateFieldEntity[] = [];
	readonly summaryLayout?: string = undefined;
	readonly gtmPageEvent?: string = undefined;

	pk() {
		return this.formPageId;
	}

	static schema = {
		sections: [TemplateSectionEntity],
		summary: [TemplateFieldEntity]
	};
}

export class FormTemplateResource extends BaseResource implements FormTemplate {
	readonly formTemplateId: ID = "";
	readonly name: string = "";
	readonly description?: string = undefined;
	readonly category?: string = undefined;
	readonly pages: (TemplatePageEntity | TemplatePageDeprecatedEntity)[] = [];
	readonly dataSource: TemplateDataSource = TemplateDataSource.CalcRules;
	readonly resource?: DataSourceResourceName = undefined;

	pk() {
		return this.formTemplateId?.toString();
	}

	static schema = {
		pages: [
			new schema.Union(
				{ page: TemplatePageEntity, deprecated: TemplatePageDeprecatedEntity },
				p => {
					if (isTemplatePage(p)) return "page";
					return "deprecated";
				}
			)
		]
	};

	static url<T extends typeof BaseResource>(
		this: T,
		urlParams: Readonly<Record<string, any>>
	) {
		const url = super.url(urlParams);
		const queryParams = {
			customerContext: urlParams.customerContext,
			isMobile: urlParams.isMobile,
			refreshCounter: urlParams.refreshCounter
		};

		return `${url}${paramsToString(queryParams)}`;
	}

	static urlTemplates = [
		`${ApplicationResource.urlRoot}/{applicationNumber}/form-templates/{formContext}/{context}`,
		`${ProductResource.urlRoot}/{productExternalCode}/form-templates/{formContext}/{context}`,
		`${apiResourceUrl("global/form-templates")}/{formContext}`
	];

	static urlRoot = apiResourceUrl("form-templates");
}

// Only useful for a form template editor.
// Use a custom class in order to avoid dirtying the original form template state.
export class UnmergedFormTemplateResource extends FormTemplateResource {
	static detail<T extends typeof Resource>(this: T) {
		const endpoint = super.detail();
		return endpoint.extend({
			url: urlParams => {
				const url = endpoint.url(urlParams);
				return `${url}?skipMerge=true`;
			}
		});
	}

	static urlTemplates = [];
}

export interface StatefulFormTemplateState {
	applicationSessionId: string;
	openClosedGroups: Record<string, boolean>;
	tableColumnOptionValues: Pick<
		TemplateTableColumn,
		"name" | "tableType" | "isMultipleSelect" | "optionValues"
	>[];
}

export class StatefulFormTemplateStateEntity
	extends Entity
	implements StatefulFormTemplateState
{
	readonly applicationSessionId: string = "";
	readonly openClosedGroups: Record<string, boolean> = {};
	readonly tableColumnOptionValues: Pick<
		TemplateTableColumn,
		"name" | "tableType" | "isMultipleSelect" | "optionValues" | "optionSource"
	>[] = [];

	pk(parent?: any, key?: string): string | undefined {
		return this.applicationSessionId ?? "";
	}
}

export interface FormTemplateStateRequest {
	applicationSessionId: ID;
	formTemplateId?: ID;
	tableColumns?: Pick<TemplateTableColumn, "tableType" | "name">[];
}

export const StatefulFormTemplateStateEndpoint = new Endpoint(
	(request: FormTemplateStateRequest) =>
		Resource.fetch(apiResourceUrl("form-templates/state-dependent-values"), {
			method: "POST",
			body: JSON.stringify(request)
		}),
	{
		schema: StatefulFormTemplateStateEntity,
		dataExpiryLength: 5 * 60 * 1000,
		key: (req: FormTemplateStateRequest) =>
			`LOAD form-template-state-dependent-values ${req.applicationSessionId}`
	}
);

export const dataSourceResources = {
	ProductResource,
	FormTemplateResource,
	LimitApplicationDataResource,
	LimitApplicationDataCustomerResource,
	LimitRedistributionResource,
	EmbeddedProductFieldResource,
	BeneficialOwnerResource
};

const resourceLabelKey: DataSourceResourceKeyMap<string | number | undefined> =
	{
		FormTemplateResource: "name",
		ProductResource: "name",
		LimitApplicationDataResource: "applicationId",
		LimitApplicationDataCustomerResource: "customerId",
		LimitRedistributionResource: "customerId",
		EmbeddedProductFieldResource: "applicationId",
		BeneficialOwnerResource: "__id"
	};

const resourceGroupKey: Partial<
	DataSourceResourceKeyMap<string | number | undefined>
> = {
	FormTemplateResource: "category",
	ProductResource: "category",
	LimitApplicationDataResource: "applicationId",
	LimitApplicationDataCustomerResource: "customerId",
	LimitRedistributionResource: "customerId",
	EmbeddedProductFieldResource: "applicationId",
	BeneficialOwnerResource: "__id"
};

type DataSourceResources = typeof dataSourceResources;

export type DataSourceResourceName = keyof DataSourceResources;

export const getResourceLabel = (s: DataSourceResourceName) =>
	startCase(s.replace(/Resource$/, ""))
		.toLowerCase()
		.replace(/^./, match => match.toUpperCase());

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type FilterValueKeys<T extends {}, V> = {
	[K in keyof T as T[K] extends V ? K : never]: T[K];
};

type DataSourceResourceKeyMap<V> = {
	[K in DataSourceResourceName]: keyof FilterValueKeys<
		AbstractInstanceType<DataSourceResources[K]>,
		V
	>;
};

export const createGetResourceInstanceLabel = <
	K extends DataSourceResourceName,
	R extends DataSourceResources[K]
>(
	resource: K
) => {
	const key = resourceLabelKey[resource];

	return (instance: AbstractInstanceType<R>): string =>
		(instance as any)[key]?.toString() ?? "";
};

export const createGetResourceInstanceGroup = <
	K extends DataSourceResourceName,
	R extends DataSourceResources[K]
>(
	resource: K
) => {
	const key = resourceGroupKey[resource];
	if (!key) return undefined;

	return (instance: AbstractInstanceType<R>): string =>
		(instance as any)[key]?.toString() ?? "";
};

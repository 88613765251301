import { useMemo, useEffect, useCallback, useState } from "react";
import {
	useResource,
	useInvalidator,
	useRetrieve,
	useFetcher
} from "@rest-hooks/core";
import {
	FormTemplate,
	CalculationResponse,
	CustomGuiFieldHandling
} from "@ploy-lib/types";
import {
	FormTemplateResource,
	AppLoadResource,
	AppInitResource,
	CalculationResource,
	MinimalInitialData
} from "@ploy-lib/rest-resources";
import { fromCGF, CgfResults } from "./fromCGF";
import mapValues from "lodash/mapValues";
import {
	ServiceBodyType,
	ServiceBodyValue,
	useCalcRuleDebugManager
} from "@ploy-lib/calculation";
import { useTemplateWithDefaults } from "./useTemplateWithDefaults";
import {
	getAllFormTemplateFields,
	getAllTemplateFields
} from "@ploy-lib/template-converter";
import { useMediaQuery, useTheme } from "@material-ui/core";

const appLoadShape = AppLoadResource.detail();
const appInitShape = AppInitResource.init();
const calculationShape = CalculationResource.detail();
const templateShape = FormTemplateResource.detail();

const emptyArray = [];

export function useCalculationResources(
	applicationNumber?: string,
	appLoadPayload?: object,
	productExternalCode?: string,
	formContext?: string,
	context?: string,
	template?: FormTemplate,
	skipInit?: boolean,
	refreshCounter?: number,
	customerContext?: string,
	initialData?: MinimalInitialData,
	disallowedFieldRoles: readonly string[] = emptyArray
) {
	const appShape = applicationNumber ? appLoadShape : appInitShape;
	const appParams = useMemo(
		() =>
			applicationNumber
				? { ...(appLoadPayload ?? { applicationNumber }) }
				: productExternalCode
				? {
						productExternalCode,
						fromSession: skipInit ? true : undefined,
						initialData: skipInit ? undefined : initialData
				  }
				: null,
		[
			appLoadPayload,
			applicationNumber,
			initialData,
			productExternalCode,
			skipInit
		]
	);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

	// Trigger template request eagerly if we know the context
	useRetrieve(
		templateShape,
		template ||
			!(applicationNumber || productExternalCode) ||
			!formContext ||
			!context
			? null
			: {
					applicationNumber,
					productExternalCode,
					formContext,
					context,
					customerContext,
					isMobile,
					refreshCounter
			  }
	);

	const app = useResource(appShape, appParams);

	context = context || (app ? app.vulcanContext : undefined);
	const { applicationStatus, id } = app || {};

	const templateParams =
		template ||
		!(applicationNumber || productExternalCode) ||
		!formContext ||
		!context
			? null
			: {
					applicationNumber,
					productExternalCode,
					formContext,
					context,
					customerContext,
					isMobile,
					refreshCounter
			  };

	const calcParams =
		!id || !formContext || !context
			? null
			: {
					session: id,
					context,
					formContext,
					applicationStatus,
					applicationNumber
			  };

	const [formTemplateCandidate, calc] = useResource(
		[templateShape, templateParams],
		[calculationShape, calcParams]
	);

	const fetchTemplate = useFetcher(templateShape);
	const fetchApp = useFetcher(appShape);

	const refetchApp = () => {
		if (templateParams) fetchTemplate(templateParams);
		fetchApp(appParams!);
		// No need to refetch calc-rules, happens automatically when app is refetched
	};

	const fetchCalcStuff = useFetcher(calculationShape);
	const refetchCalcRules = () => {
		console.log("refreshing calc rules");
		fetchCalcStuff(calcParams);
	};

	const [updatedCalc, setUpdatedCalc] = useState(calc);
	// Clear the clear field after one render when it has done its job
	// Not a big fan of the logic here.
	// But as we are currently using two states (formik + calculator state) we are stuck with this
	useEffect(() => {
		if (updatedCalc?.clear && updatedCalc.clear.length > 0)
			setUpdatedCalc(CalculationResource.fromJS({ ...updatedCalc, clear: [] }));
	}, [updatedCalc]);

	const currentCalc = updatedCalc?.pk() === calc?.pk() ? updatedCalc : calc;

	const refreshNamespaces = useCallback(
		(updates: CalculationResource) => {
			setUpdatedCalc(state => {
				const old = state?.pk() === calc?.pk() ? state : calc;

				const { id, context, formContext, formTemplate, ...mergeableUpdates } =
					updates;

				return old
					? CalculationResource.merge(old, mergeableUpdates as any)
					: state;
			});
		},
		[calc]
	);

	const formTemplate = template || formTemplateCandidate;

	const invalidateApp = useInvalidator(appShape);
	const invalidateCalc = useInvalidator(calculationShape);

	// Invalidate current cached app/calc requests as they are tied to session
	useEffect(() => () => invalidateApp(appParams), [invalidateApp, appParams]);

	useEffect(
		() => () =>
			invalidateCalc({
				id,
				context,
				formContext,
				applicationStatus,
				applicationNumber
			}),
		[
			invalidateCalc,
			context,
			formContext,
			id,
			applicationStatus,
			applicationNumber
		]
	);

	const { enabled: debugEnabled, setCalculationResponse } =
		useCalcRuleDebugManager();

	useEffect(() => {
		if (debugEnabled && currentCalc) {
			setCalculationResponse(currentCalc);
		}
	}, [debugEnabled, currentCalc, setCalculationResponse]);

	var allFields = Array.from(getAllFormTemplateFields(formTemplate));
	const [calcSetup, cgf] = useMemo((): [
		CalculationResponse<any> | undefined,
		CgfResults
	] => {
		if (currentCalc) {
			const cgf = fromCGF(
				currentCalc.model.namespacedCGFSections,
				currentCalc.variables,
				currentCalc.services,
				currentCalc.customSubmitFields,
				debugEnabled,
				allFields
			);

			const variables = mapValues(currentCalc.variables, (v, ns) =>
				v.concat(cgf.additionalVariables[ns])
			);

			const services = currentCalc.services;

			return [
				{
					...currentCalc,
					variables,
					services
				},
				cgf
			];
		}
		return [
			undefined,
			{
				cgfMap: {},
				additionalFunctions: [],
				additionalVariables: {},
				initialChecked: {},
				initialValues: {},
				initialVisible: {},
				mapTemplateFieldDefaults: identityToArray,
				serviceBodyFields: {}
			}
		];
	}, [allFields, currentCalc, debugEnabled]);

	const templateWithDefaults = useTemplateWithDefaults(
		formTemplate,
		cgf.mapTemplateFieldDefaults,
		formContext,
		disallowedFieldRoles
	);

	const additionalServiceBody = useMemo<
		Record<string, ServiceBodyValue[]>
	>(() => {
		const serviceBody: Record<string, ServiceBodyValue[]> = {};

		// Custom submit fields from CalcRules request (e.g. "LockedElements")
		if (currentCalc?.customSubmitFields) {
			for (const [namespace, fields] of Object.entries(
				currentCalc.customSubmitFields
			)) {
				serviceBody[namespace] = Object.entries(fields).map(
					([name, value]) => ({
						name,
						namespace,
						type: ServiceBodyType.Text,
						value
					})
				);
			}
		}

		// Fields that are not connected to CalcRules, only defined in template.
		// Should still be submitted.
		if (templateWithDefaults) {
			for (const field of getAllTemplateFields(templateWithDefaults)) {
				if (
					field.cgfHandling === CustomGuiFieldHandling.Ignore &&
					field.namespace
				) {
					serviceBody[field.namespace] ??= [];

					serviceBody[field.namespace].push({
						field: field.name,
						storageName: field.storageName,
						namespace: field.namespace,
						type: ServiceBodyType.Text
					});
				}
			}
		}

		return serviceBody;
	}, [currentCalc?.customSubmitFields, templateWithDefaults]);

	return [
		app,
		refetchApp,
		calcSetup,
		templateWithDefaults,
		cgf,
		additionalServiceBody,
		refreshNamespaces,
		refetchCalcRules
	] as const;
}

const identityToArray = <T,>(i: T) => [i];

import { FieldType, TableColumnMetadata } from "./types";

interface PropertyInterface {
	readonly refinance: boolean;
	readonly streetName: string;
	readonly addressNumber: string;
	readonly postalCode: string;
	readonly cadastralNumber: string;
	readonly municipalityNumber: string;
	readonly cadastralUnitNumber: string;
	readonly unitNumber: string;
	readonly leaseholdUnitNumber: string;
	readonly valueEnteredByCustomer: string;
	readonly estimatedValue: string;
	readonly utilizedValue: string;
	readonly ownedBy: string;
}

export class PropertySchema {
	static metaData: Partial<
		Record<keyof PropertyInterface, TableColumnMetadata>
	> = {
		refinance: {
			fieldType: FieldType.Checkbox,
			alwaysEditable: true
		},
		streetName: {
			fieldType: FieldType.Text
		},
		addressNumber: {
			fieldType: FieldType.Text
		},
		postalCode: {
			fieldType: FieldType.Text
		},
		cadastralNumber: {
			fieldType: FieldType.Text
		},
		municipalityNumber: {
			fieldType: FieldType.Text
		},
		cadastralUnitNumber: {
			fieldType: FieldType.Text
		},
		unitNumber: {
			fieldType: FieldType.Text
		},
		leaseholdUnitNumber: {
			fieldType: FieldType.Text
		},
		valueEnteredByCustomer: {
			fieldType: FieldType.Currency
		},
		estimatedValue: {
			fieldType: FieldType.Currency,
			alwaysEditable: true
		},
		utilizedValue: {
			fieldType: FieldType.Currency,
			alwaysEditable: true
		},
		ownedBy: {
			fieldType: FieldType.Multiselect
		}
	};
}

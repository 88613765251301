import React, { useCallback, useState, useEffect } from "react";
import {
	useCalculationField,
	useUpdateSavedValues,
	useVariableData
} from "@ploy-lib/calculation";
import { InputFieldProps } from "../types";
import SearchIcon from "@material-ui/icons/Search";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Grid from "@material-ui/core/Grid";

import * as puiFormFields from "@ploy-ui/form-fields";
import * as literalFields from "./literals";

import { DployFormControl, useSelectedField } from "@ploy-ui/form-fields";

import {
	useOptionSource,
	useServiceHandler,
	useAlternativeFormik,
	useDisabledOverride
} from "../hooks";
import { FormLabel, FormHelperText, makeStyles } from "@material-ui/core";
import { PendingButton, BisnodeSearchField } from "@ploy-ui/core";

import {
	Fulfillment,
	FulfillmentReadOnly,
	Refinancing,
	RefinancingReadOnly
} from "./Refinancing";
import { CarSummaryField } from "./CarInfoSummary";
import { CommentField } from "./Comment";
import { DriverSelect } from "./DriverSelect";
import { CommisionSection } from "../section/custom";
import { ButtonField, ButtonLink } from "./Buttons/Button";
import { OpenModalButton } from "./Buttons/OpenModalButton";
import { SubmitButton } from "./Buttons/SubmitButton";
import { useServiceHref, ServiceResult } from "@ploy-lib/calculation";
import { getIn } from "formik";
import clsx from "clsx";
import { SearchSelectList } from "./SearchSelectList";
import { EquitySlider, TermsYearsSlider, ObjectPriceSlider } from "./Sliders";
import * as customfields from "./customFields";
import { ErrorDisplay } from "@ploy-lib/types";
import { AppActionButton } from "./Buttons/AppActionButton";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { useIsMountedRef } from "../hooks/useIsMountedRef";
import { MarkdownField } from "./MarkdownField";
import { SeparatorField } from "./SeparatorField";
import { defineMessages, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { NewTabButton } from "./Buttons/NewTabButton";
import { GoToSblButton } from "./Buttons/GoToSblButton";
import { ProductSelector } from "./customFields/ProductSelector";
import { usePageState } from "../PageContext";

const formFields = {
	...puiFormFields,
	...customfields,
	AppActionButton,
	ButtonField,
	ButtonLink,
	OpenModalButton,
	SubmitButton,
	Refinancing,
	RefinancingReadOnly,
	Fulfillment,
	FulfillmentReadOnly,
	CarSummaryField,
	CommentField,
	CommissionSummary: CommisionSection,
	BisnodeSearch: BisnodeSearchField,
	SearchSelectList,
	DriverSelect,
	EquitySlider,
	TermsYearsSlider,
	ObjectPriceSlider,
	MarkdownField,
	NewTabButton,
	GoToSblButton,
	ProductSelector,
	SeparatorField
};

const formLiterals = {
	...customfields,
	...literalFields,
	CarSummaryLiteral: CarSummaryField,
	CommentLiteral: CommentField,
	MarkdownLiteral: MarkdownField,
	SeparatorLiteral: SeparatorField
};

const ENTER_KEY = 13;

const serviceErrorMessages = defineMessages({
	generalErrorMessage: {
		id: `error-snackbar.general-error`,
		description: `General snackbar error message`,
		defaultMessage: `An error occured`
	}
});

const useButtonStyles = makeStyles(() => ({
	iconInLabel: {
		verticalAlign: "bottom"
	}
}));

export function CalculationField(props: InputFieldProps) {
	const {
		className,
		label,
		form: formikForm,
		field: formikField,
		meta,
		formTemplateFieldId,
		fieldName,
		namespace,
		literal,
		disabled: propsDisabled,
		justifyContent,
		optionSource,
		search,
		save,
		click,
		open,
		renderAs,
		renderAsLiteral,
		margin,
		variant,
		color,
		icon,
		role,
		saveOnClick,
		width,
		suffix,
		prefix,
		placeholder,
		emptyEqualsZero,
		allowNegative,
		maximumFractionDigits,
		hasClick,
		allowEmpty,
		formatString,
		boldText,
		italicText,
		alwaysEnabled,
		errorDisplay = ErrorDisplay.Touched,
		errorDisplayLiteral = ErrorDisplay.Never,
		modalText,
		textAlign,
		mailto,
		linkIsUrl,
		disableValidation,
		action,
		optionValues,
		textAreaRows,
		onChange: formikOnChange,
		onBlur: formikOnBlur,
		onClick: formikOnClick,
		pageToOpen,
		redirectPathKey,
		buttonSize,
		muiStartIcon,
		muiEndIcon,
		...rest
	} = props;

	const buttonClasses = useButtonStyles();

	const calculation = useCalculationField(namespace, fieldName);
	const { enqueueSnackbar = () => {} } = useSnackbar() || {};
	const intl = useIntl();

	// Avoid loading resources if override has no effect
	const canOverride = !literal && calculation.enabled && !alwaysEnabled;
	const disabledOverrideState = useDisabledOverride(
		canOverride ? fieldName : null
	);

	const generalErrorMessage = intl.formatMessage(
		serviceErrorMessages.generalErrorMessage
	);

	// calculation variables convert NaN to 0 with missing: true
	const variableValue =
		!emptyEqualsZero && calculation.value === 0 && calculation.missing
			? NaN
			: calculation.value;

	const classes = useStyles(props);
	const { form, field } = useAlternativeFormik(
		formikField,
		formikForm,
		variableValue
	);

	const [onSearch, searchPending, result] = useServiceHandler(
		click,
		props.field,
		namespace
	);

	useEffect(() => {
		if (result && result.ok == false)
			enqueueSnackbar(generalErrorMessage, { variant: "error" });
	}, [result, enqueueSnackbar]);

	const href = useServiceHref(
		(open && open.namespace) || namespace,
		open && open.service
	);

	const { value: pendingItemsFromService } = useVariableData<string>(
		optionSource && optionSource.pendingFillOptionValues
			? optionSource.pendingFillOptionValues[0]
			: "",
		optionSource && optionSource.pendingFillOptionValues
			? optionSource.pendingFillOptionValues[1]
			: ""
	);

	const optionProps = useOptionSource(
		field,
		props.form,
		namespace,
		optionSource,
		onSearch
	);

	const [saveState, setSaveState] = useState<{
		value?: any;
		loading?: boolean;
		success?: boolean;
		error?: Error;
	}>({});

	const isMountedRef = useIsMountedRef();

	const { goto } = usePageState();

	const updateSavedValue = useUpdateSavedValues();
	const onSave = useCallback(async () => {
		try {
			const pristine = calculation.initialFieldValue === field.value;
			const saved = saveState.value === field.value;
			const hasErrors = getIn(props.form.errors, field.name);
			if (pristine || saved || hasErrors || !isMountedRef.current) return;

			setSaveState({ loading: true });

			const response = await fetch(
				legacyApiResourceUrl("AppChange/UpdateFieldWithEvent"),
				{
					method: "POST",
					body: new Blob(
						[
							JSON.stringify({
								value: field.value,
								field: fieldName
							})
						],
						{ type: "application/json" }
					),
					headers: new Headers({ accept: "application/json" }),
					credentials: "include"
				}
			);

			const data = response.headers.get("content-type")?.includes("json")
				? await response.json()
				: undefined;

			if (!data.error) {
				updateSavedValue({
					name: fieldName,
					namespace,
					value: field.value
				});

				if (!isMountedRef.current) return;
				setSaveState({ success: true, value: field.value });
			}
		} catch (error: any) {
			if (!isMountedRef.current) return;

			setSaveState({ error });
		}
	}, [
		calculation.initialFieldValue,
		field.value,
		field.name,
		saveState.value,
		props.form.errors,
		isMountedRef,
		fieldName,
		updateSavedValue,
		namespace
	]);

	const { setFieldTouched } = form;

	const searchOnEnter = useCallback(
		async (e: React.KeyboardEvent) => {
			const key = e.which || e.keyCode;
			if (key === ENTER_KEY && onSearch) {
				await onSearch();
				setFieldTouched(field.name, true);
			}
		},
		[field.name, setFieldTouched, onSearch]
	);

	let fieldComponent =
		renderAs && formFields[renderAs] ? renderAs : "TextField";

	// Override TextField with SelectField if select items list is defined
	if (
		(fieldComponent === "TextField" || fieldComponent === "NumberField") &&
		optionProps &&
		optionProps.items.length > 0
	)
		fieldComponent = "SelectField";

	// Map field component to literal component
	let literalComponent =
		renderAsLiteral || fieldComponent.replace("Field", "Literal");

	literalComponent = formLiterals[literalComponent]
		? literalComponent
		: "TextLiteral";

	let FieldComponent =
		literal && renderAs !== "AppActionButton"
			? formLiterals[literalComponent]
			: formFields[fieldComponent];

	const selectedFieldCallback = useSelectedField({
		name: fieldName,
		namespace: namespace
	});

	const onClick = useCallback(
		(e: React.MouseEvent<Element>) => {
			if (formikOnClick) formikOnClick(e);
			selectedFieldCallback("CLICK");
			if (save && save.type === "button") {
				onSave();
			}
		},
		[formikOnClick, onSave, save, selectedFieldCallback]
	);

	const onChange = useCallback(
		(e: React.ChangeEvent<Element>) => {
			if (field.onChange) {
				field.onChange(e);
			} else if (formikOnChange) {
				formikOnChange(e);
			}
			selectedFieldCallback("CHANGE");
			if (save && saveState.success) {
				setSaveState(s => ({ ...s, success: undefined }));
			}
		},
		[field, formikOnChange, save, saveState.success, selectedFieldCallback]
	);

	const onBlur = useCallback(
		(e: React.FocusEvent<Element>) => {
			if (formikOnBlur) formikOnBlur(e);
			selectedFieldCallback("BLUR");
		},
		[formikOnBlur, selectedFieldCallback]
	);

	if (!FieldComponent) {
		console.error(
			`Unknown ${literal ? "literal " : ""}field component`,
			literal ? literalComponent : fieldComponent,
			"for field",
			field,
			calculation
		);
		FieldComponent = literal ? formLiterals.TextLiteral : formFields.TextField;
	}

	const errorDisplayToUse = literal ? errorDisplayLiteral : errorDisplay;

	let isButton =
		fieldComponent === "NewTabButton" ||
		fieldComponent === "ButtonLink" ||
		fieldComponent === "ButtonField" ||
		fieldComponent === "GoToSblButton" ||
		fieldComponent === "SubmitButton";

	const disabled =
		((disabledOverrideState.disabled ?? propsDisabled) ||
			!calculation.enabled ||
			(literal && !isButton)) &&
		!alwaysEnabled;

	const fieldComponentOnBlur = useCallback(
		(e: React.FocusEvent<Element>) => {
			if (onBlur) onBlur(e);
			if (save && save.type !== "button") onSave();
		},
		[onBlur, onSave, save]
	);

	const fieldComponentOnClick = useCallback(
		async (e: React.MouseEvent<Element>): Promise<ServiceResult | null> => {
			if (save && save.type !== "button" && onClick) onClick(e);
			if (isButton && onSearch) {
				return await onSearch(e);
			} else {
				return null;
			}
		},
		[save, onClick, isButton, onSearch]
	);

	const fieldElement = (
		<FieldComponent
			fieldName={{ name: fieldName, namespace: namespace }}
			{...optionProps}
			items={optionValues ?? optionProps?.items}
			className={classes.field}
			role={role}
			label={label}
			icon={icon}
			id={formTemplateFieldId}
			variant={variant}
			color={color}
			multiple={props.multiple}
			disableClearable={props.disableClearable}
			row={props.horizontal}
			manual={props.manual}
			margin={margin}
			onKeyPress={searchOnEnter}
			onBlur={fieldComponentOnBlur}
			onClick={fieldComponentOnClick}
			onChange={onChange}
			goto={goto}
			disabled={disabled}
			pending={
				searchPending ||
				pendingItemsFromService === "1" ||
				disabledOverrideState.loading
			}
			options={{
				...rest,
				alwaysEnabled,
				textAreaRows,
				disableValidation,
				href: rest.href || href
			}}
			emptyEqualsZero={emptyEqualsZero}
			allowNegative={allowNegative}
			maximumFractionDigits={maximumFractionDigits}
			field={field}
			form={form}
			suffix={suffix === "_" ? "" : suffix}
			prefix={prefix}
			clearable={allowEmpty}
			fullWidth={width !== "auto"}
			saveOnClick={saveOnClick}
			placeholder={placeholder}
			formatString={formatString}
			boldText={boldText}
			italicText={italicText}
			modalText={modalText}
			errorDisplay={errorDisplayToUse}
			textAlign={textAlign}
			mailto={mailto}
			linkIsUrl={linkIsUrl}
			literal={literal}
			action={action}
			pageToOpen={pageToOpen}
			redirectPathKey={redirectPathKey}
			buttonSize={buttonSize}
			muiStartIcon={muiStartIcon}
			muiEndIcon={muiEndIcon}
			hasClick={hasClick}
		/>
	);

	let buttonElement: JSX.Element | undefined = undefined;

	if (!buttonElement && save && save.type === "button" && !literal) {
		const pristine = calculation.initialFieldValue === field.value;
		const saved = saveState.value === field.value;
		const fieldError = getIn(props.form.errors, field.name);

		buttonElement = (
			<DployFormControl
				variant={variant as any}
				margin={margin as any}
				error={Boolean(saveState.error)}
				className={classes.saveButtonContainer}
			>
				{label && <FormLabel> </FormLabel>}
				<PendingButton
					pending={saveState.loading}
					disabled={
						((pristine || saveState.success) && !alwaysEnabled) || fieldError
					}
					success={saved}
					error={Boolean(saveState.error)}
					variant="contained"
					color="primary"
					size="large"
					onClick={onClick}
					className={classes.searchButton}
					fullWidth
					hideChildrenWhilePending
					hideChildrenOnComplete
				>
					<ArrowForwardIcon className={buttonClasses.iconInLabel} />
				</PendingButton>
				{saveState.error && (
					<FormHelperText>{saveState.error.message}</FormHelperText>
				)}
			</DployFormControl>
		);
	}

	const handleMouseDown = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};

	const buttonElementOnClick = useCallback(
		async (e: React.MouseEvent<Element>) => {
			if (onClick) onClick(e);
			if (onSearch) await onSearch(e);
			form.setFieldTouched(field.name, true);
		},
		[onClick, onSearch, form, field.name]
	);

	if (!buttonElement && click && !isButton && !literal) {
		buttonElement = (
			<DployFormControl
				variant={variant as any}
				margin={margin as any}
				className={classes.searchButtonContainer}
			>
				{label && <FormLabel> </FormLabel>}
				<PendingButton
					pending={searchPending}
					variant="outlined"
					size="large"
					onClick={buttonElementOnClick}
					success={false}
					onMouseDown={handleMouseDown}
					disabled={disabled}
					className={classes.searchButton}
					fullWidth
					hideChildrenWhilePending
				>
					<SearchIcon className={buttonClasses.iconInLabel} />
				</PendingButton>
			</DployFormControl>
		);
	}

	return (
		<>
			<Grid
				container
				wrap="nowrap"
				justifyContent={justifyContent}
				className={clsx(className, classes.root)}
			>
				{fieldElement && buttonElement ? (
					<>
						<Grid item xs={9}>
							{fieldElement}
						</Grid>
						<Grid item xs={3}>
							{buttonElement}
						</Grid>
					</>
				) : fieldElement ? (
					fieldElement
				) : buttonElement ? (
					buttonElement
				) : null}
			</Grid>
		</>
	);
}

const useStyles = makeStyles(
	{
		root: {
			alignSelf: "end"
		},
		field: {},
		searchButton: {
			padding: 0,
			minWidth: 0
		},
		searchButtonContainer: {
			width: "100%"
		},
		saveButton: {},
		saveButtonContainer: {
			width: "100%"
		}
	},
	{ name: "DployCalculationField" }
);

CalculationField.displayName = "DployCalculationField";

export default CalculationField;

import { CalculatorState } from "../calculator";
import { isNotNull, getInNamespaced } from "../calculator/utils";
import {
	isBodyVariable,
	isBodyVariableField,
	isBodyField,
	isBodyStaticValue
} from "./utils";
import { ServiceBodyValue, ServiceBodyType } from "../types";

export function calculationToFieldList<TNamespaces extends string, TData>(
	calculation: Partial<CalculatorState<TNamespaces, TData>> = {},
	additionalServiceBody: Partial<Record<TNamespaces, ServiceBodyValue[]>> = {},
	...namespaces: TNamespaces[]
) {
	const { formValues, serviceBodyValuesMap, values, isWriteLocked, isMissing } =
		calculation;

	return namespaces.flatMap(namespace => {
		const serviceBodyValues = (serviceBodyValuesMap?.[namespace] ??
			[]) as ServiceBodyValue[];

		const fieldList = serviceBodyValues
			.concat(additionalServiceBody[namespace] ?? [])
			.map(v => {
				let value: any;
				let missing: boolean = false;
				let writeLocked: boolean = false;
				let name: string;

				if (isBodyStaticValue(v)) {
					name = v.name;
					value = v.value;
				} else if (isBodyVariableField(v)) {
					name = v.field;
					value = getInNamespaced(values, namespace, v.variable);
					missing = getInNamespaced(isMissing, namespace, v.variable);
					writeLocked = getInNamespaced(isWriteLocked, namespace, v.variable);
				} else if (isBodyField(v)) {
					name = v.field;
					value = getInNamespaced(formValues, namespace, v.field);
				} else if (isBodyVariable(v)) {
					name = v.variable;
					value = getInNamespaced(values, namespace, v.variable);
					missing = getInNamespaced(isMissing, namespace, v.variable);
					writeLocked = getInNamespaced(isWriteLocked, namespace, v.variable);
				} else {
					return null;
				}

				if (missing && value === 0) value = NaN;

				const coerceValue = (val: any) => {
					switch (v.type) {
						case ServiceBodyType.Number:
							val = typeof val === "number" ? val : Number(val);
							break;
						case ServiceBodyType.Text:
							switch (typeof val) {
								case "bigint":
									val = val.toString();
									break;
								case "number":
									val = Number.isNaN(val) ? "" : val.toString();
									break;
								case "boolean":
									val = val ? "1" : "0";
									break;
								case "object":
									if (val != null) {
										if (typeof val.join === "function")
											val = `^${val.join("$|^")}$`;
										else val = JSON.stringify(val);
									}
									break;
							}
							break;
					}
					return val;
				};

				return {
					namespace,
					name: !!v.storageName ? v.storageName : name,
					typeName: v.typeName ? v.typeName : undefined,
					complexData: v.typeName ? value : undefined,
					[v.type]: v.typeName
						? undefined
						: v.multiple
						? value?.map?.(coerceValue) ?? []
						: coerceValue(value),
					checked: writeLocked ? "1" : undefined
				};
			})
			.filter(isNotNull);

		return fieldList;
	});
}

import { ID } from "../types";
import { apiResourceUrl } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";
import {
	SimpleResource,
	SchemaList,
	AbstractInstanceType
} from "@rest-hooks/rest";

export enum ApplicationDebtSource {
	NotSet = 0,
	Taxdata = 1,
	DebtRegistry = 2,
	Applicant = 3
}

export interface ApplicationDebt {
	termAmount: number;
	refinance: boolean;
	nominalInterest?: number;
	maturity?: number;
	applicationDebtId?: ID;
	amount: number;
	comment: string;
	creditLimit?: number;
	customerId?: number;
	lender: string;
	loanNumber: string;
	loanType: string;
	source: ApplicationDebtSource;
	refinancedAccount: string;
}

export interface ApplicationDebtParams {
	applicationNumber: string;
}

export class ApplicationDebtResource
	extends BaseResource
	implements ApplicationDebt
{
	readonly termAmount: number = 0;
	readonly refinance: boolean = false;
	readonly nominalInterest?: number;
	readonly maturity?: number;
	readonly applicationDebtId?: ID;
	readonly amount: number = 0;
	readonly comment: string = "";
	readonly creditLimit?: number;
	readonly customerId?: number;
	readonly lender: string = "";
	readonly loanNumber: string = "";
	readonly loanType: string = "";
	readonly refinancedAccount: string = "";
	readonly source: ApplicationDebtSource = ApplicationDebtSource.NotSet;

	pk() {
		return `${this.applicationDebtId}`;
	}

	/** Shape to update a list of existing entity (put) */
	static updateList<T extends typeof SimpleResource>(this: T) {
		return this.update().extend({
			schema: [this] as SchemaList<AbstractInstanceType<T>>
		});
	}

	// 		Enable optimistic updates?
	//
	// static getEndpointExtra(): FetchOptions {
	// 	return {
	// 		...super.getEndpointExtra(),
	// 		optimisticUpdate: (params, body) => body
	// 	};
	// }

	static urlRoot = apiResourceUrl("applications/{applicationNumber}/debts");
}

import { defineMessages } from "react-intl";

export default defineMessages({
	selectAllChecbox: {
		id: "table.selectAllCheckbox",
		defaultMessage: "Velg Alle",
		description: "Label for global table checkbox"
	},
	"limitdetails.customerId": {
		id: "table.limitdetails.Customer",
		defaultMessage: "Kunde",
		description: "Label for customer"
	},
	"limitdetails.customers": {
		id: "table.limitdetails.Customers",
		defaultMessage: "Kunde",
		description: "Label for customer"
	},
	"limitdetails.objectTypes": {
		id: "table.limitdetails.ObjectTypes",
		defaultMessage: "Gjenstandsgruppe",
		description: "Label for objecttypes"
	},
	"limitdetails.limitAmount": {
		id: "table.limitdetails.LimitAmount",
		defaultMessage: "Rammebeløp",
		description: "Label for limitamount"
	},
	"limitdetails.limitApplicationNumber": {
		id: "table.limitdetails.limitApplicationNumber",
		defaultMessage: "Rammesøknad",
		description: "Label for limitApplicationNumber"
	},
	"limitdetails.loanAmount": {
		id: "table.limitdetails.LoanAmount",
		defaultMessage: "Fin.beløp",
		description: "Label for loanamount"
	},
	"limitdetails.usedAmount": {
		id: "table.limitdetails.UsedAmount",
		defaultMessage: "Opptrekk",
		description: "Label for usedamount"
	},
	"limitdetails.remainingAmount": {
		id: "table.limitdetails.remainingAmount",
		defaultMessage: "Restbeløp",
		description: "Label for restamount"
	},
	"limitdetails.products": {
		id: "table.limitdetails.Products",
		defaultMessage: "Produkt",
		description: "Label for products"
	},
	"limitdetails.minEquity": {
		id: "table.limitdetails.MinEquity",
		defaultMessage: "Startleie %",
		description: "Label for min equity"
	},
	"limitdetails.objectCondition": {
		id: "table.limitdetails.ObjectCondition",
		defaultMessage: "Tilstand",
		description: "Label for object condition"
	},
	"limitdetails.terms": {
		id: "table.limitdetails.Terms",
		defaultMessage: "Løpetid",
		description: "Label for terms"
	},
	"limitdetails.vatRule": {
		id: "table.limitdetails.VatRule",
		defaultMessage: "MVA på lån",
		description: "Label for vatrule"
	},
	"limitdetails.pd": {
		id: "table.limitdetails.PD",
		defaultMessage: "PD Kunde",
		description: "Label for PD Customer"
	},
	"limitdetails.lgd": {
		id: "table.limitdetails.LGD",
		defaultMessage: "LGD Ramme",
		description: "Label for LGD Limit"
	},
	"limitdetails.lgdAmount": {
		id: "table.limitdetails.LGDAmount",
		defaultMessage: "LGD Beløp",
		description: "Label for LGD Amount"
	},
	"limitdetails.toDate": {
		id: "table.limitdetails.toDate",
		defaultMessage: "Sluttdato",
		description: "Label for to date"
	},
	"limitdetails.usedDepotValue": {
		id: "table.limitdetails.usedDepotValue",
		defaultMessage: "DV Opptrekk",
		description: "Label for used depot value"
	},
	"limitdetails.name": {
		id: "table.limitdetails.name",
		defaultMessage: "Gjenstandsgruppe",
		description: "Label for name"
	},
	"limitdetails.normalizedLoss": {
		id: "table.limitdetails.normalizedLoss",
		defaultMessage: "NT",
		description: "Label for normalizedLoss"
	},
	"limitdetails.depotValue": {
		id: "table.limitdetails.depotValue",
		defaultMessage: "DV Ramme",
		description: "Label for depotValue"
	},
	"guarantor.customerName": {
		id: "table.Guarantor.CustomerName",
		defaultMessage: "Navn",
		description: "Label for Customer Name"
	},
	"guarantor.customerNumberCombined": {
		id: "table.Guarantor.CustomerNumberCombined",
		defaultMessage: "Org.nr/Pers.nr.",
		description: "Label for Customer Number"
	},
	"guarantor.guarantorAmount": {
		id: "table.Guarantor.GuarantorAmount",
		defaultMessage: "Garantibeløp",
		description: "Label for Guarantor Amount"
	},
	"guarantor.guarantorType": {
		id: "table.Guarantor.GuarantorType",
		defaultMessage: "Garantitype",
		description: "Label for Guarantor Type"
	},
	"applications.applicationNumber": {
		id: "table.ApplicationLimitResource.ApplicationNumber",
		defaultMessage: "Søknad/Kontrakt",
		description: "Label for applicationnumber"
	},
	"applications.applicationStatus": {
		id: "table.ApplicationLimitResource.ApplicationStatus",
		defaultMessage: "Status",
		description: "Label for applicationstatus"
	},
	"applications.objectGroup": {
		id: "table.ApplicationLimitResource.objectGroup",
		defaultMessage: "Gj.gruppe",
		description: "Label for objectGroup"
	},
	"applications.objectCondition": {
		id: "table.ApplicationLimitResource.objectCondition",
		defaultMessage: "Tilstand",
		description: "Label for objectCondition"
	},
	"applications.customerAgreementId": {
		id: "table.ApplicationLimitResource.customerAgreementId",
		defaultMessage: "Ramme",
		description: "Label for customerAgreementId"
	},
	"properties.refinance": {
		id: "table.PROPERTIES.refinance",
		defaultMessage: "Select",
		description: "Label for select property"
	},
	"properties.streetName": {
		id: "table.PROPERTIES.streetName",
		defaultMessage: "Street name",
		description: "Label for property streetName"
	},
	"properties.addressNumber": {
		id: "table.PROPERTIES.addressNumber",
		defaultMessage: "Address number",
		description: "Label for property addressNumber"
	},
	"properties.postalCode": {
		id: "table.PROPERTIES.postalCode",
		defaultMessage: "Postal code",
		description: "Label for property postalCode"
	},
	"properties.postalPlace": {
		id: "table.PROPERTIES.postalPlace",
		defaultMessage: "Postal place",
		description: "Label for property postalPlace"
	},
	"properties.municipalityNumber": {
		id: "table.PROPERTIES.municipalityNumber",
		defaultMessage: "Municipality number",
		description: "Label for property municipalityNumber"
	},
	"properties.cadastralUnitNumber": {
		id: "table.PROPERTIES.cadastralUnitNumber",
		defaultMessage: "Cadastral unit number",
		description: "Label for property cadastralUnitNumber"
	},
	"properties.unitNumber": {
		id: "table.PROPERTIES.unitNumber",
		defaultMessage: "Unit number",
		description: "Label for property unitNumber"
	},
	"properties.leaseholdUnitNumber": {
		id: "table.PROPERTIES.leaseholdUnitNumber",
		defaultMessage: "Leasehold uinit number",
		description: "Label for property leaseholdUnitNumber"
	},
	"properties.valueEnteredByCustomer": {
		id: "table.PROPERTIES.valueEnteredByCustomer",
		defaultMessage: "Override Value",
		description: "Label for property valueEnteredByCustomer"
	},
	"properties.estimatedValue": {
		id: "table.PROPERTIES.estimatedValue",
		defaultMessage: "Estimated Value",
		description: "Label for property estimatedValue"
	},
	"properties.utilizedValue": {
		id: "table.PROPERTIES.utilizedValue",
		defaultMessage: "Utilized Value",
		description: "Label for property utilizedValue"
	},
	"properties.ownedBy": {
		id: "table.PROPERTIES.ownedBy",
		defaultMessage: "Owned By",
		description: "Label for property ownedBy"
	},
	"properties.cadastralNumber": {
		id: "table.PROPERTIES.cadastralNumber",
		defaultMessage: "Official ID",
		description: "Label for property cadastralNumber"
	},
	"properties.securityValue": {
		id: "table.PROPERTIES.securityValue",
		defaultMessage: "Security Value",
		description: "Label for property securityValue"
	}
});

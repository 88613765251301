import React, { useMemo } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { IntlProvider } from "react-intl";
import Context from "../context";
import { getDefaultFormats } from "../formats";
import LuxonUtils from "@date-io/luxon";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { DateType } from "@date-io/type";
import { useTenantSettings } from "../hooks/useTenantSettings";
import { DEFAULT_DESIGN } from "../endpoints/loadTenantSettings";
import { useSettings } from "@ploy-lib/core";
import { useDesignSettings } from "../hooks/useDesignSettings";
import { IUtils } from "@date-io/core/IUtils";
import { DployBrandTheme } from "@ploy-lib/types";
import { TenantIntlProviderProps } from "./TenantIntlProvider";

export interface TenantProviderProps<TDate = DateType>
	extends TenantIntlProviderProps {
	dateUtils?: IUtils<TDate>;
	tenant?: string;
	tenantOverride?: string;
	useAlternativeStyle?: boolean;
	theme?: Partial<DployBrandTheme>;
	skipDesign?: boolean;
	skipTenantSelector?: boolean;
}

export interface DployBrandThemeAdditions {
	formWebLogoDisabled: boolean;
}

const loaders = {
	tenant: (key: string) =>
		import(
			/* webpackInclude: /tenant\.js$/, webpackChunkName: "tenants/[request]/tenant" */
			`../tenants/${key}/tenant`
		),
	messages: async (lang: string) =>
		(
			await import(
				/* webpackInclude: /\.json$/, webpackChunkName: "lang/[request]" */
				`../translations/${lang.toLowerCase()}`
			)
		)?.default
};

function CreateAdditions(
	dployBrandTheme?: Partial<DployBrandTheme>
): DployBrandThemeAdditions {
	return {
		formWebLogoDisabled: dployBrandTheme?.formWebLogoDisabled ?? false
	};
}
//
export function TenantProvider<TDate = DateType>(
	props: TenantProviderProps<TDate>
) {
	const settings = useSettings();

	const {
		tenantOverride,
		locale: currentLocale = settings.locale,
		dateUtils = LuxonUtils,
		defaultCurrency = "NOK",
		defaultLocale = "nb-NO",
		children,
		theme: dployTheme,
		skipDesign,
		images
	} = props;

	let tenantName = props.tenant ?? settings.tenant ?? "Demo";
	if (tenantOverride?.startsWith(tenantName)) {
		tenantName = tenantOverride;
	}
	const defaultFormats = useMemo(
		() => getDefaultFormats(defaultCurrency),
		[defaultCurrency]
	);

	const { brand, formats, locale, messages, tenant } = useTenantSettings(
		tenantName,
		loaders,
		currentLocale,
		defaultLocale,
		images
	);

	let design = dployTheme?.design ?? settings.design ?? DEFAULT_DESIGN.id;
	design = tenant.designs[design] ? design : DEFAULT_DESIGN.id;

	const { theme } = useDesignSettings(
		tenant,
		skipDesign ? undefined : design,
		props.theme
	);

	const brandAdditions = CreateAdditions(dployTheme);

	const contextValue = useMemo(
		() => ({
			brand,
			formats,
			locale,
			messages,
			tenant,
			theme,
			design,
			brandAdditions
		}),
		[brand, design, formats, locale, messages, tenant, theme, brandAdditions]
	);

	return (
		<Context.Provider value={contextValue}>
			<IntlProvider
				defaultLocale={defaultLocale}
				defaultFormats={defaultFormats}
				messages={messages}
				locale={locale}
				formats={formats}
				onError={error => {
					// Default onError behaviour is log error if NODE_ENV !== "production"
					if (
						process.env.NODE_ENV !== "production" &&
						error.code !== "MISSING_TRANSLATION"
					) {
						console.error(error);
					}
				}}
			>
				<MuiPickersUtilsProvider utils={dateUtils} locale={locale}>
					<ThemeProvider theme={theme}>{children}</ThemeProvider>
				</MuiPickersUtilsProvider>
			</IntlProvider>
		</Context.Provider>
	);
}

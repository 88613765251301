import React, { CSSProperties, forwardRef } from "react";
import MuiTextField, {
	TextFieldProps as MuiTextFieldProps
} from "@material-ui/core/TextField";
import MuiFormControl, {
	FormControlProps as MuiFormControlProps
} from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { DployFieldName } from "@ploy-lib/types";

const useLabelStyles = makeStyles(
	{
		root: {
			whitespace: "break-spaces",
			position: "relative",
			top: 0,
			marginBottom: -16
		},
		shrink: {
			transform: "translate(0, -20px) !important"
		}
	},
	{ name: "DployTextFieldLabel" }
);

const useFormControlStyles = makeStyles(
	{
		marginDense: {
			marginTop: 16
		},
		marginNormal: {}
	},
	{ name: "DployFormControl" }
);

const useInputStyles = makeStyles(
	(props: DployTextFieldProps) => ({
		inputVariantOutline: {
			borderRadius: "0px"
		},
		selectVariantOutline: {
			borderRadius: 0,
			borderTop: "none",
			borderLeft: "none",
			borderRight: "none"
		},
		noBorderVariantOutline: {
			borderStyle: "none"
		},
		multiline: {
			padding: 0
		},
		input:
			typeof props.textAlign === "string"
				? {
						textAlign: props.textAlign as CSSProperties["textAlign"]
				  }
				: {},
		square: {
			borderStyle: "none"
		},
		inputSquare: {
			height: "100%",
			width: "100%",
			resize: "both",
			borderStyle: "solid",
			borderWidth: "0.933px",
			minHeight: "150px",
			fontFamily: "Arial,Helvetica Neue,Helvetica,Roboto,sans-serif !important",
			fontSize: "11px",
			borderColor: "#a59bbf"
		}
	}),
	{ name: "DployInput" }
);

const dployVariants = ["select", "input", "noBorder"] as const;
const muiVariants = ["standard", "outlined", "filled"] as const;

type ValidDployVariants =
	| typeof muiVariants[number]
	| typeof dployVariants[number];

export type DployTextFieldProps = Omit<MuiTextFieldProps, "variant"> & {
	fieldName?: DployFieldName;
	variant?: ValidDployVariants;
	textAlign?: string;
	square?: boolean;
};

export const DployTextFieldInternal = (
	props: DployTextFieldProps,
	ref: React.Ref<any>
) => {
	const labelClasses = useLabelStyles(props);
	const inputClasses = useInputStyles(props);

	const { textAlign, ...rest } = props;

	if (
		rest.variant &&
		muiVariants.includes(rest.variant as typeof muiVariants[number])
	) {
		const variant = rest.variant as typeof muiVariants[number];
		return <MuiTextField ref={ref} {...rest} variant={variant} />;
	}

	const { variant = "input" } = props;
	const variantType = variant + "VariantOutline";

	return (
		<MuiTextField
			{...rest}
			ref={ref}
			autoComplete={props.autoComplete || "off"}
			variant="outlined"
			InputProps={{
				...props.InputProps,
				notched: false,
				classes: {
					input: `${inputClasses.input} ${
						props.square ? inputClasses.inputSquare : {}
					}`,
					multiline: inputClasses.multiline,
					...(props.InputProps && props.InputProps.classes),
					notchedOutline: `${props.square ? inputClasses.square : {}} ${
						inputClasses[variantType]
					}`
				}
			}}
			InputLabelProps={{
				shrink: true,
				classes: labelClasses,
				...props.InputLabelProps
			}}
		/>
	);
};

DployTextFieldInternal.displayName = "DployTextField";

const DployTextField = forwardRef(DployTextFieldInternal);

export type DployFormControlProps = MuiFormControlProps & {
	variant?: ValidDployVariants;
	textAlign?: string;
};

const DployFormControlInternal = (
	props: DployFormControlProps,
	ref: React.Ref<HTMLDivElement>
) => {
	const formControlClasses = useFormControlStyles(props);

	const { textAlign, ...rest } = props;

	if (rest.variant && muiVariants.includes(rest.variant))
		return <MuiFormControl variant="standard" ref={ref} {...rest} />;

	return (
		<MuiFormControl
			{...rest}
			ref={ref}
			variant="outlined"
			classes={formControlClasses}
		/>
	);
};

DployFormControlInternal.displayName = "DployFormControl";

const DployFormControl = forwardRef(DployFormControlInternal);

export { DployTextField, DployFormControl };

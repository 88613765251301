import React, { useState, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DployTable, DployColumn } from "../DployTable";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { processPayments } from "./helpers";
import { Payment, AccumulatedPayment } from "./types";
import { FormattedMessage, FormattedNumber } from "react-intl";

const useStyle = makeStyles({
	root: {
		maxWidth: "100%"
	}
});

const padding: (minimized: boolean) => React.CSSProperties = minimized =>
	minimized ? { padding: "4px 16px 4px 0" } : { padding: "4px 12px" };

const paymentColumns: (
	minimized: boolean
) => DployColumn<AccumulatedPayment>[] = minimized => [
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.year.title"
				description="Payment plan year title"
				defaultMessage="Year"
			/>
		),
		field: "year",
		type: "numeric",
		render: rowData => {
			if (rowData.isTerm) {
				if (minimized)
					return (
						<FormattedMessage
							id="core.payment-plan.term.term-value"
							description="Payment plan term term value"
							defaultMessage="{term}.month"
							values={{
								term: rowData.term! - 12 * (rowData.year! - 1)
							}}
						/>
					);
				return rowData.year;
			}
			if (typeof rowData.year !== "undefined") {
				if (minimized)
					return (
						<FormattedMessage
							id="core.payment-plan.term.year-value"
							description="Payment plan term year value"
							defaultMessage="{year}.year"
							values={{
								year: rowData.year
							}}
						/>
					);
				return rowData.year;
			}
			return null;
		},
		headerStyle: padding(minimized),
		cellStyle: padding(minimized),
		sorting: false
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.term.title"
				description="Payment plan term title"
				defaultMessage="Term"
			/>
		),
		field: "term",
		type: "numeric",
		headerStyle: padding(minimized),
		cellStyle: padding(minimized),
		sorting: false,
		hidden: minimized
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.term-amount.title"
				defaultMessage="Term amount"
				description="payment plan termAmount title"
			/>
		),
		field: "termAmount",
		type: minimized ? "numeric" : "currency",
		headerStyle: padding(minimized),
		cellStyle: padding(minimized),
		round: true,
		sorting: false
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.installment.title"
				defaultMessage="Installment"
				description="payment plan installment title"
			/>
		),
		field: "installment",
		type: minimized ? "numeric" : "currency",
		headerStyle: padding(minimized),
		cellStyle: padding(minimized),
		round: true,
		sorting: false
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.interest.title"
				defaultMessage="Interest rate"
				description="payment plan interest title"
			/>
		),
		field: "interest",
		type: minimized ? "numeric" : "currency",
		headerStyle: padding(minimized),
		cellStyle: padding(minimized),
		round: true,
		sorting: false
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.residual.title"
				defaultMessage="Residual balance"
				description="payment plan residual title"
			/>
		),
		field: "residual",
		type: minimized ? "numeric" : "currency",
		formatOptions: {
			minimumFractionDigits: 0,
			maximumFractionDigits: 0
		},
		headerStyle: padding(minimized),
		cellStyle: padding(minimized),
		sorting: false
	},
	{
		title: (
			<FormattedMessage
				id="core.payment-plan.extra.title"
				defaultMessage="Emb. prod."
				description="payment plan extra title"
			/>
		),
		field: "extra",
		type: minimized ? "numeric" : "currency",
		headerStyle: padding(minimized),
		cellStyle: padding(minimized),
		sorting: false
	}
];

const hiddenCollapsed = ["year", "residual", "extra", "termAmount"];

export interface PaymentPlanProps {
	payments?: Payment[];
	collapsible?: boolean;
	initialCollapsed?: boolean;
	title?: string;
	minimized?: boolean;
}

export const PaymentPlan = memo((props: PaymentPlanProps) => {
	let {
		collapsible = false,
		initialCollapsed = false,
		title,
		minimized = false
	} = props;

	const [year, setYear] = useState(minimized ? 0 : 1);
	const [collapsed, setCollapsed] = useState(initialCollapsed);

	const classes = useStyle(props);

	const { payments, years, accumulated } = processPayments(
		props.payments || []
	);

	var indexOfYear = years.findIndex(t => t.year === year);

	const total: AccumulatedPayment = {
		...accumulated,
		id: "total",
		residual:
			accumulated.residual && accumulated.residual > 0
				? accumulated.residual
				: undefined,
		year: undefined,
		term: undefined
	};
	var yearsBeforePayments: AccumulatedPayment[];
	if (indexOfYear === -1) yearsBeforePayments = [];
	else if (minimized)
		yearsBeforePayments = years.slice(
			0,
			indexOfYear === -1 ? 0 : indexOfYear + 1
		);
	else
		yearsBeforePayments = indexOfYear === -1 ? [] : years.slice(0, indexOfYear);
	const yearsAfterPayments = years.slice(indexOfYear + 1, years.length);

	var data = [
		...yearsBeforePayments,
		...payments.filter(p => p.year === year),
		...yearsAfterPayments,
		total
	];

	var paymentColumnsToUse = paymentColumns(minimized);

	let columns = paymentColumnsToUse.filter(
		c => typeof c.field !== "string" || accumulated[c.field] != null
	);

	columns = columns.map(col => {
		const { field, hidden } = col;
		return {
			...col,
			hidden:
				hidden ||
				(typeof field === "string"
					? !data.some(r => Number(r[field]) > 0)
					: false)
		};
	});

	if (collapsed) {
		columns = columns.map(col => ({
			...col,
			hidden: col.field ? hiddenCollapsed.includes(col.field.toString()) : false
		}));
	}

	return (
		<div className={classes.root}>
			<DployTable
				title={title}
				data={data}
				columns={columns}
				options={{
					draggable: false,
					toolbar: title != null || collapsible,
					showTitle: title != null,
					sorting: false,
					search: false,
					paging: false,
					headerStyle: minimized
						? { backgroundColor: "transparent" }
						: undefined,
					rowStyle: row => ({
						backgroundColor: row.isTerm ? "#EEE" : "#transparent",
						borderTop: row.term == null ? "2px solid #666" : ""
					})
				}}
				actions={
					collapsible
						? [
								{
									icon: () => (collapsed ? <ChevronRight /> : <ChevronLeft />),
									// tooltip: collapsed ? "Vis mer" : "Vis mindre",
									onClick: () => setCollapsed(!collapsed),
									isFreeAction: true
								}
						  ]
						: undefined
				}
				onRowClick={(_, row) =>
					row && row.year != null && setYear(year === row.year ? 0 : row.year)
				}
			/>
		</div>
	);
});

import { useCache, useResource } from "@rest-hooks/core";
import { LoginResource, StyleResource } from "@ploy-lib/rest-resources";
import { TenantProvider, TenantProviderProps } from "@ploy-ui/tenants";

export const BrandTenantProvider = <TDate extends {}>(
	props: TenantProviderProps<TDate>
) => {
	const login = useCache(LoginResource.status(), {});

	const style: Partial<StyleResource> =
		useResource(
			StyleResource.detail(),
			login && login.isAuthenticated
				? {
						useAlternativeStyle: props.useAlternativeStyle ?? false,
						vendorId: login.vendor?.id,
						username: login.user?.username
				  }
				: null
		) ?? {};

	const { theme, images } = style;

	return (
		<TenantProvider
			{...props}
			theme={{
				...theme,
				...props.theme,
				design: theme?.design?.length ? theme?.design : props.theme?.design
			}}
			images={{ ...images, ...props.images }}
			dashboardImageCropMode={props.dashboardImageCropMode}
		/>
	);
};

import React, { useState, Suspense, useEffect } from "react";
import { SectionProps } from "../Section";
import {
	Grid,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	LinearProgress,
	useMediaQuery,
	useTheme,
	List,
	ListItem,
	Typography
} from "@material-ui/core";
import { useFetcher } from "@rest-hooks/core";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import { useTenantImage } from "@ploy-ui/tenants";

import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { FormattedMessage, useIntl } from "react-intl";
import {
	SigningDocumentResource,
	useResourceWithInvalidate,
	ApplicationSignerResource,
	UploadedDocumentResource,
	VendorDocumentGroupResource,
	ID
} from "@ploy-lib/rest-resources";
import { Dropzone } from "@ploy-ui/form-fields";
import { useAppLoad } from "../../appLoadContext";
import { useResource } from "@rest-hooks/core";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { useSearchParams } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import { ImageFit } from "@ploy-ui/core";
import { inherits } from "util";

const useStyles = makeStyles(
	theme => ({
		dropZoneArea: {
			borderWidth: "1px",
			borderStyle: "dashed",
			margin: theme.spacing(0.5),
			color: "black",
			backgroundColor: theme.palette.common.white,
			borderColor: theme.palette.primary.main
		},
		dropZoneInner: {
			gap: theme.spacing(2),
			justifyContent: "center",
			alignItems: "center",
			padding: theme.spacing(2, 0),
			width: "inherit"
		},
		circleIconContainer: { marginLeft: theme.spacing(2), padding: "unset" },
		clearIcon: {
			float: "right"
		},
		dropZoneText: { padding: theme.spacing(0), margin: "unset" },
		documentListSection: {},
		documentListCard: {
			border: "1px",
			borderStyle: "solid",
			alignItems: "center",
			borderRadius: "16px",
			backgroundColor: "white",
			padding: theme.spacing(2, 2),
			margin: theme.spacing(1, 0)
		},
		documentListHeader: { margin: theme.spacing(1, 0), fontWeight: 775 }
	}),
	{ name: "DployDocumentDropzoneSection" }
);

const uploadDocument = (
	file: File,
	documentType: string | null,
	customerContext: string | null
) => {
	const formData = new FormData();
	formData.append("file", file, file.name);
	if (documentType) formData.append("documentType", documentType);
	if (customerContext) formData.append("customerContext", customerContext);

	return fetch(legacyApiResourceUrl("DocumentGrid/Upload"), {
		method: "POST",
		body: formData
	});
};
// @todo: This is a legacy API call, and should be replaced with a REST call using the rest-hooks library.
const deleteDocument = documentId => {
	return fetch(legacyApiResourceUrl("DocumentGrid/DeleteUploadedDocument"), {
		method: "POST",
		headers: new Headers({
			"Content-type": "application/json"
		}),
		body: JSON.stringify({
			documentId: documentId
		})
	});
};

const ConfirmDialog = ({ open, text, onConfirm, onDeny }) => {
	return (
		<Dialog open={open}>
			<DialogTitle>{text}</DialogTitle>
			<DialogActions>
				<Button onClick={onDeny} color="primary">
					<FormattedMessage
						id="template-form.sections.document-upload.confirm-no"
						defaultMessage="No"
					/>
				</Button>
				<Button onClick={onConfirm} color="primary">
					<FormattedMessage
						id="template-form.sections.document-upload.confirm-yes"
						defaultMessage="Yes"
					/>
				</Button>
			</DialogActions>
		</Dialog>
	);
};

addRegisteredSectionLayout({
	name: "DocumentDropzoneSection",
	displayName: "DocumentDropzoneSection",
	settings: {
		editableOptions: {
			allowEditSameSession: true
		}
	}
});

interface DocumentDropzoneSectionProps extends SectionProps {
	useDropzone?: boolean;
}

const DocumentDropzoneSection = (props: DocumentDropzoneSectionProps) => {
	// Fetch hooks start
	const docIcon = useTenantImage("Factoring");
	const intl = useIntl();
	const { applicationNumber, id: appSessionId } = useAppLoad();
	const [errorMessages, setErrorMessages] = useState<string[] | undefined>([]);

	const isDirectUser = true;

	const [searchParams, _] = useSearchParams();
	const customerContext = searchParams.get("customerContext");

	const refetchSigningDocuments = useFetcher(SigningDocumentResource.list());
	const refetchSigners = useFetcher(ApplicationSignerResource.list());

	const documentList = useResourceWithInvalidate(
		UploadedDocumentResource.list(),
		appSessionId ? { applicationNumber, customerContext } : null
	);

	const [initialDocuments, setInitialDocuments] =
		useState<(ID | undefined)[]>();

	useEffect(() => {
		if (initialDocuments) return;
		setInitialDocuments(documentList?.map(x => x.documentId) ?? []);
	}, [documentList, initialDocuments]);

	const refetchDocuments = useFetcher(UploadedDocumentResource.list());
	// only deletes from the cache, we don't have a method that overrides the delete method in rest-hooks
	const deleteDocumentFromResource = useFetcher(
		UploadedDocumentResource.delete()
	);

	const fetchDocGroups = !isDirectUser && !!appSessionId;

	const documentGroups = useResource(
		VendorDocumentGroupResource.list(),
		fetchDocGroups ? { applicationNumber } : null
	);

	// Fetch hooks end
	const [selectedGroup, setSelectedGroup] =
		useState<VendorDocumentGroupResource | null>(null);

	useEffect(() => {
		let defaultGroup = documentGroups?.find(dg => dg.isDefaultGroup);
		if (defaultGroup) setSelectedGroup(defaultGroup);
	}, [documentGroups]);

	const classes = useStyles(props);
	const { className, onClick } = props;

	const filteredDocumentGroups =
		documentGroups &&
		documentGroups.filter(
			dg =>
				dg.allowMultipleUploads ||
				!(
					documentList &&
					documentList.some(d => d.documentType === dg.documentCode)
				)
		);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [selectedDocument, setSelectedDocument] =
		useState<UploadedDocumentResource | null>(null);
	const [uploadLoading, setUploadLoading] = useState(false);

	useEffect(() => {
		if (filteredDocumentGroups && filteredDocumentGroups.length === 1)
			setSelectedGroup(filteredDocumentGroups[0]);
		else if (
			selectedGroup &&
			filteredDocumentGroups &&
			!filteredDocumentGroups.some(
				dg => dg.documentCode === selectedGroup.documentCode
			)
		)
			setSelectedGroup(null);
	}, [filteredDocumentGroups, selectedGroup]);

	const allowedFileTypes: string[] = [
		".pdf",
		".jpg",
		".png",
		".xsl",
		".xlsx",
		".doc",
		".docx"
	];
	return (
		<Grid container className={className} onClick={onClick}>
			<Suspense fallback={<LinearProgress />}>
				<Grid container style={{}}>
					<Grid item xs={12}>
						<Dropzone
							className={classes.dropZoneArea}
							disabled={uploadLoading}
							label={
								<Grid
									item
									container
									xs={12}
									className={classes.dropZoneInner}
									direction="row"
									wrap="nowrap"
								>
									<Grid
										container
										item
										xs={1}
										className={classes.circleIconContainer}
									>
										<AddCircleIcon
											sx={{
												width: "40px",
												height: "40px"
											}}
										/>
									</Grid>
									<Grid
										item
										container
										xs={"auto"}
										className={classes.dropZoneText}
										justifyContent="center"
										alignItems="flex-start"
										direction="column"
									>
										<Grid item>
											<Typography
												variant="body1"
												style={{
													fontSize: "16px",
													fontWeight: 771,
													color: "#00002B"
												}}
											>
												<FormattedMessage
													id="template-form.section.Document-dropzone-upload.UploadText"
													defaultMessage="Velg fil"
													description="Logo"
												/>
											</Typography>
										</Grid>
										<Grid item style={{}}>
											<Typography
												variant="caption"
												style={{
													fontSize: "14px",
													fontWeight: 771,
													color: "#404060"
												}}
											>
												<FormattedMessage
													id="template-form.section.Document-dropzone.ValidFiles"
													defaultMessage="Støtter .pdf .jpg .png .doc .xls"
													description="Logo"
												/>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							}
							files={[]}
							imgBackground="#FFFFFF"
							accept={allowedFileTypes}
							maxSize={20 ** 19}
							onChange={files => {
								setUploadLoading(true);
								uploadDocument(
									files[0] as File,
									selectedGroup && selectedGroup!.documentCode,
									customerContext
								).then(response => {
									response
										.json()
										.then(result => {
											if (result.error) {
												setErrorMessages([result.error]);
											} else {
												setErrorMessages([]);
											}
										})
										.catch(error => {
											setErrorMessages([
												intl.formatMessage({
													id: "template-form.sections.document-upload.genericuploaderror",
													defaultMessage: "Det skjedde en feil."
												})
											]);
										});
									setUploadLoading(false);
									if (selectedGroup && selectedGroup.mustSign) {
										refetchSigningDocuments({ applicationNumber });
										refetchSigners({ applicationNumber, signerType: 0 });
									}
									refetchDocuments({ applicationNumber, customerContext });
								});
							}}
							onError={messages => setErrorMessages(messages)}
						/>
					</Grid>
					<Grid item style={{ color: "#f44336" }}>
						{errorMessages}
					</Grid>
				</Grid>
				{documentList && documentList.length > 0 ? (
					<Grid item container xs={12} style={{ background: "transparent" }}>
						<Suspense fallback={<LinearProgress />}>
							<Grid xs={12} item>
								<Typography
									variant="body1"
									className={classes.documentListHeader}
								>
									<FormattedMessage
										id="template-form.sections.document-dropzone-upload.uploades-documents-header"
										description="Label for Uploaded documents Button"
										defaultMessage="Uploaded"
									/>
								</Typography>
							</Grid>
							<List style={{ width: "100%", padding: "unset" }}>
								{documentList.map(x => (
									<ListItem
										style={{
											padding: "unset",
											width: "100%"
										}}
									>
										<Grid
											container
											item
											xs={12}
											className={classes.documentListCard}
											style={{}}
										>
											<Grid item xs={1}>
												{docIcon ? (
													<ImageFit src={docIcon} alt="document Icon" />
												) : (
													<DescriptionIcon />
												)}
											</Grid>
											<Grid
												item
												container
												xs={10}
												direction="column"
												style={{
													alignSelf: "center",
													overflow: "hidden",
													textOverflow: "ellipsis"
												}}
											>
												<Link
													href={legacyApiResourceUrl(
														"/DocumentGrid/GetDocument" +
															"?documentId=" +
															x.documentId
													)}
													style={{ width: "inherit" }}
													className="file-upload__document-name"
													underline="hover"
												>
													<Typography
														variant="body1"
														noWrap
														style={{ fontSize: "18px", color: "#00002B" }}
													>
														{x.filename.split(".")[0]}
													</Typography>
													<Typography
														variant="caption"
														style={{ fontSize: "14px", color: "#404060" }}
													>
														{x.fileSize / 1000} kB
													</Typography>
												</Link>
											</Grid>
											<Grid item xs={1}>
												<ClearIcon
													className={classes.clearIcon}
													onClick={() => {
														setSelectedDocument(x);
														setOpenConfirmDialog(true);
													}}
													style={{ cursor: "pointer" }}
												/>
											</Grid>
										</Grid>
									</ListItem>
								))}
							</List>
						</Suspense>
					</Grid>
				) : null}

				<ConfirmDialog
					open={openConfirmDialog}
					text={
						<FormattedMessage
							id="template-form.sections.document-upload.delete-document-confirm"
							description="Confirm deletion of uploaded document"
							defaultMessage="Er du sikker på at du vil slette dokumentet?"
						/>
					}
					onDeny={() => setOpenConfirmDialog(false)}
					onConfirm={() => {
						setOpenConfirmDialog(false);
						deleteDocument(selectedDocument!.documentId).then(() => {
							deleteDocumentFromResource({
								documentId: selectedDocument!.documentId
							});
							refetchSigningDocuments({ applicationNumber });
							refetchSigners({ applicationNumber, signerType: 0 });
							refetchDocuments({ applicationNumber });
						});
					}}
				/>
			</Suspense>
		</Grid>
	);
};

DocumentDropzoneSection.displayName = "DployDocumentDropzoneSection";

export { DocumentDropzoneSection };
